import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import firebase from "firebase/compat/app"
import {getMessaging,onMessage} from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyCrkKSaDvoIWeFZXe6I8b01OeaFROzJxKk",
  authDomain: "meeeindia.firebaseapp.com",
  projectId: "meeeindia",
  storageBucket: "meeeindia.appspot.com",
  messagingSenderId: "975004917324",
  appId: "1:975004917324:web:ef52f4e8d8f937ca26dee9",
  measurementId: "G-1PNQGR9MDB"
};


    // eslint-disable-next-line
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
  } else {
      firebase.app(); // if already initialized, use that one
  }

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app)
  // onMessage(messaging, (payload) => {
  //   console.log("payload", payload)

  // });

const authentication = getAuth();


export {app, authentication,messaging};
